
import { defineComponent } from 'vue';
import { RouteName } from '@/router/models';

export default defineComponent({
  name: 'Forward',
  beforeRouteEnter (to, _from, next) {
    const destination = to.query.destination as string;
    if (destination) {
      const destinationParams = destination.split(':');
      const routeName = destinationParams[0];
      if (routeName === 'calendar') {
        const eventId = destinationParams[1];
        const query = {
          ...to.query
        };
        delete query.destination;
        if (eventId) {
          query.event = eventId;
        }
        next({
          name: RouteName.SCHEDULER,
          query
        });
      }
    }
  }
});
