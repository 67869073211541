
import { defineComponent, inject } from 'vue';
import {
  Form,
  Field
} from 'vee-validate';
import {
  DmWrapper,
  DmInput,
  DmButton
} from '@dobrymechanik/vue-ui';
import { CookiesKey, LocalStorageKey } from '@/models/storage';
import { URI_SET_PASSWORD } from '@/api/endpoints';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import jwtDecode from 'jwt-decode';
import { RouteName } from '@/router/models';

interface Credentials {
  password: string;
}

export default defineComponent({
  name: 'SetNewPassword',
  components: {
    DmForm: Form,
    DmField: Field,
    DmWrapper,
    DmInput,
    DmButton
  },

  data () {
    return {
      loading: false as boolean,
      homeRoute: {
        name: RouteName.HOME
      }
    };
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    validatePassword (value: string): string | true {
      if (!value) {
        return this.$t('login.noPassword');
      }
      if (value.length < 8) {
        return this.$t('setPassword.error');
      }
      return true;
    },
    async submit (values: Credentials): Promise<void> {
      this.loading = true;
      try {
        await this.$http.post(URI_SET_PASSWORD, {
          token: this.$route.params.token,
          plainPassword: values.password
        });

        this.createNotification({
          message: 'Udało się! Twoje hasło zostało zmienione',
          type: 'success'
        });
      } catch (e) {
        this.createNotification({
          message: 'Wystąpił nieznany błąd podczas zmiany hasła. Spróbuj ponownie',
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    }
  }
});
