import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/logo-v.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmInput = _resolveComponent("DmInput")
  const _component_DmField = _resolveComponent("DmField")
  const _component_DmForm = _resolveComponent("DmForm")
  const _component_DmWrapper = _resolveComponent("DmWrapper")

  return (_openBlock(), _createBlock(_component_DmWrapper, {
    class: _ctx.$bem({}),
    "data-test": "login-page"
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: _ctx.$bem({ e: 'header' })
      }, [
        _createVNode(_component_DmButton, {
          to: _ctx.homeRoute,
          hoverable: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('login.goToHomePage')), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ], 2),
      _createVNode(_component_DmForm, {
        class: _ctx.$bem({ e: 'form' }),
        onSubmit: _ctx.submit
      }, {
        default: _withCtx(({ errors }) => [
          _createVNode("img", {
            src: _imports_0,
            class: _ctx.$bem({ e: 'logo' })
          }, null, 2),
          _createVNode("h2", {
            class: _ctx.$bem({ e: 'form-header' })
          }, _toDisplayString(_ctx.$t('setPassword.title')), 3),
          _createVNode(_component_DmField, {
            name: "password",
            rules: _ctx.validatePassword
          }, {
            default: _withCtx(({ field }) => [
              _createVNode(_component_DmInput, _mergeProps({
                modelValue: field.value,
                "onUpdate:modelValue": ($event: any) => (field.value = $event),
                label: _ctx.$t('login.password'),
                type: "password"
              }, field, {
                rounded: "",
                light: "",
                error: errors.password,
                class: _ctx.$bem({ e: 'field' }),
                "data-test": "field-password"
              }), null, 16, ["modelValue", "onUpdate:modelValue", "label", "error", "class"])
            ]),
            _: 2
          }, 1032, ["rules"]),
          _createVNode(_component_DmButton, {
            color: "primary",
            rounded: "",
            dark: "",
            type: "submit",
            block: "",
            loading: _ctx.loading,
            class: _ctx.$bem({ e: 'submit-button' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.save')), 1)
            ]),
            _: 1
          }, 8, ["loading", "class"])
        ]),
        _: 1
      }, 8, ["class", "onSubmit"])
    ]),
    _: 1
  }, 8, ["class"]))
}